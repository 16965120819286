
import AuthImage from '@/components/image/AuthImage.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import UploadModelDialog from '@/modules/library/components/UploadModelDialog.vue'
import CategoriesArrayToMap from '@/plugins/filters'
import { Permissions } from '@/security/permissions'
import { Tenant } from '@/store/modules/app/types'
import { LibraryCategories, LibraryCategory } from '@/store/modules/teamlibrary/types'
import { Namespace, PermissionActions } from '@/store/types'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

@Component({
  components: {
    'prompt-yes-no': PromptYesNo,
    'upload-model-dialog': UploadModelDialog,
    'auth-image': AuthImage,
  },
})

export default class MyModels extends Vue {
  @State('libraryCategories', { namespace: Namespace.TeamLibrary })
  public libraryCategories?: LibraryCategories
  @State('modelsLibrary', { namespace: Namespace.TeamLibrary })
  public modelsLibrary!: any[]
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('libraryItemsPerPage', { namespace: Namespace.Utils })
  public libraryItemsPerPage!: number
  @State('initializing', { namespace: Namespace.Utils })
  public initializing!: boolean
  @State('loading', { namespace: Namespace.Utils })
  public loading!: boolean

  @Action('loadLibraryCategories', { namespace: Namespace.TeamLibrary })
  public loadLibraryCategories: any
  @Action('loadTenantsLibrary', { namespace: Namespace.TeamLibrary })
  public loadTenantsLibrary: any
  @Action('deleteLibraryEntryById', { namespace: Namespace.TeamLibrary })
  public deleteLibraryEntryById: any

  public $refs!: Vue['$refs'] & {
    deleteItemPromptYesNo: PromptYesNo,
  }

  private get libraryCategoriesMap(): Map<number, LibraryCategory> {
    if (this.libraryCategories != null) {
      return CategoriesArrayToMap(this.libraryCategories.Categories)
    } else {
      return new Map()
    }
  }

  private allModelLibraryHeaders: any[] = [
    {
      text: '',
      value: 'ThumbnailUrl',
      sortable: false,
    },
    {
      text: 'Name',
      value: 'DisplayName',
      sortable: true,
    },
    {
      text: 'Id',
      value: 'Id',
      sortable: true,
      requiresJigStaff: true,
    },
    {
      text: 'Tags',
      value: 'Tags',
      align: 'right',
      sortable: true,
      requiresJigStaff: true,
    },
    {
      text: 'Categories',
      value: 'Categories',
      align: 'right',
      sortable: true,
      requiresJigStaff: true,
    },
    {
      text: 'Visibility',
      value: 'Visibility',
      sortable: true,
      requiresJigStaff: true,
    },
    {
      text: 'Updated',
      value: 'DateUpdated',
      sortable: true,
      align: 'right',
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
      align: 'right',
    },
  ]

  private searchCriteria: string = ''
  private selectedItem: any = { Id: '', DisplayName: '' }
  private canCreate: boolean = false
  private canView: boolean = false
  private canEdit: boolean = false
  private canDelete: boolean = false
  private isJigSpaceStaff: boolean = false

  private get SelectedItem(): any {
    return this.selectedItem
  }

  protected async created() {
    const promiseCategories = this.loadLibraryCategories()
    if (await promiseCategories) {
      await this.loadTenantsLibrary()
    }
  }

  protected async mounted() {
    this.checkPermissions()
  }

  private async deleteItem() {
    if (await this.deleteLibraryEntryById(this.selectedItem.Id)) {
      await this.loadTenantsLibrary()
    }
  }

  private showDeleteItemPrompt(item: any) {
    this.selectedItem = item
    this.$refs.deleteItemPromptYesNo.ShowPrompt(item)
  }

  private get myModelLibraryHeaders() {
    if (this.isJigSpaceStaff) {
      return this.allModelLibraryHeaders
    } else {
      return this.allModelLibraryHeaders.filter(h => !h.requiresJigStaff)
    }
  }

  @Watch('myTenantIndex')
  private onMyTenantIndexPropertyChanged(value: number, oldValue: number) {
    this.checkPermissions()
    if (this.canView) {
      this.loadTenantsLibrary()
    }
  }

  @Watch('initializing')
  private onInitializingChanged(value: boolean) {
    this.checkPermissions()
    if (this.canView) {
      this.loadTenantsLibrary()
    }
  }

  private checkPermissions() {
    this.canCreate = false
    this.canView = false
    this.canEdit = false
    this.canDelete = false
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant !== null && tenant !== undefined) {
      this.isJigSpaceStaff = tenant.ID === TenantHelpers.GetJigSpaceStaffTenantID()

      const tenantTokens = this.$auth0.tenants
      const tenantToken = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenant.ID)
      if (tenantToken !== undefined) {
        this.canCreate = Permissions.TokenHasPermissionAction(
          tenantToken, Permissions.PermLibrary, PermissionActions.Create)
        this.canView = Permissions.TokenHasPermissionAction(
          tenantToken, Permissions.PermLibrary, PermissionActions.View)
        this.canEdit = Permissions.TokenHasPermissionAction(
          tenantToken, Permissions.PermLibrary, PermissionActions.Edit)
        this.canDelete = Permissions.TokenHasPermissionAction(
          tenantToken, Permissions.PermLibrary, PermissionActions.Delete)
      }
    }
  }

  private showUploadLibraryEntryDialog() {
    (this.$refs.uploadModelDialog as any).showDialog()
  }

  private itemsPerPageChange(newItemsPerPage: number) {
    // This is a 2 way binding
    this.$store.state.utils.libraryItemsPerPage = newItemsPerPage
  }
}
